// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    environment: "development",
    btlVsSubscriptionKey: '00547ec18ee748ccb90031bca91971fd',
    // backendBaseUrl: 'https://catalystdev.bcnl.digitalaviationservices.com/artifact-service/api',
    backendBaseUrl: 'https://btl-artifact-service-be-dev-westus-as.azurewebsites.net/btl-artifact-service-backend/api',
    // backendBaseUrl: 'http://localhost:8080/api',
    backendCmsVmBaseUrl: 'https://cms-vm-dev-westus-as.azurewebsites.net/cloud-cms-service/api',
    // backendCmsVmBaseUrl: 'https://catalystdev.bcnl.digitalaviationservices.com/cloud-cms-vm-service-dev/api',
    backendCmsVmSubscriptionKey: 'a4becc1d951b492aa55bbea04447d1d2',
    backendCMSBaseURL: 'https://catalystdev.bcnl.digitalaviationservices.com/cloud-cms-service/api',
    backendCMSBaseSubscriptionKey: 'f44bd412dcf449b8b975ba74d7e6108b'
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  