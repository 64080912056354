<div *ngIf="showBanner">
    <lib-status-message></lib-status-message>
</div>
<div *ngIf="fileUploadForm">
    <form [formGroup]="fileUploadForm">
        <div style="margin-left: 20px;">
            <div [formGroup]="fileUploadForm">
                <div class="header-div">
                    <div class="header">{{getUploadHeader()}}</div>
                    <div class="cross">
                        <span>
                            <mat-icon class="btn-icon" (click)="cancelUpload()">close</mat-icon>
                        </span>
                    </div>
                </div>
                <div class="upload-div" *ngIf="selectedDocType !== undefined">
                    <span class="mat-button-wrapper">
                        <label class="asterix fw-bold">File</label><br />
                        <label class="mb-1">Upload a video or the most recent version</label><br />
                        <button mat-raised-button class="upload-btn" [disabled]="disableUploadBtn"
                            (click)="fileInput.click()">
                            <span class="mat-button-wrapper">
                                <mat-icon class="btn-icon">cloud_upload</mat-icon>
                                BROWSE
                            </span>
                        </button>
                        <input type="file" #fileInput style="display: none" (change)="handleFileSelect($event)">
                    </span>
                    <span *ngIf="selectedFile" class="selected-file-name">
                        {{ selectedFile.name }}
                    </span>
                </div>
                <div class="upload-thumbnail-div" *ngIf="selectedDocType === 'tsvideo' || selectedDocType === 'mtvideo' || selectedDocType === 'ftvideo'">
                    <span class="mat-button-wrapper">
                        <label>Image</label><br />
                        <label class="mb-1">Upload an image for the video thumbnail</label><br />
                        <button mat-raised-button class="upload-btn" [disabled]="disableUploadBtn"
                            (click)="imageFileInput.click()">
                            <span class="mat-button-wrapper">
                                <mat-icon class="btn-icon">cloud_upload</mat-icon>
                                BROWSE
                            </span>
                        </button>
                        <input type="file" #imageFileInput style="display: none" (change)="handleThumbnailFileUpload($event)">
                    </span>
                    <span *ngIf="selectedThumbnailFile" class="selected-file-name">
                        {{ selectedThumbnailFile.name }}
                    </span>
                </div>
                <div class="row">
                    <div class="input-field-task input-field example-box"
                        [ngClass]="{'col-md-4':selectedDocType === 'mtspkg' || selectedDocType === 'mtvideo' || selectedDocType === 'ftvideo' || selectedDocType === 'flightcets' || selectedDocType === 'able'}">
                        <mat-form-field appearance="fill" class="form-input">
                            <mat-label class="input-label large-label asterix">Doc Group</mat-label>
                            <mat-select (selectionChange)="changeDocType($event.value)">
                                <mat-option [value]="docGroup.value"
                                    *ngFor="let docGroup of docGroups;">{{docGroup.key}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="input-field-task input-field example-box col-md-4" *ngIf="selectedDocType === 'mtspkg' || selectedDocType === 'mtvideo' || selectedDocType === 'ftvideo' || selectedDocType === 'flightcets' || selectedDocType === 'able'">
                        <mat-form-field appearance="fill" class="form-input">
                            <mat-label class="input-label large-label asterix">Model</mat-label>
                            <mat-select (selectionChange)="selectedModel = $event.value">
                                <mat-option [value]="model"
                                    *ngFor="let model of models;">{{model.modelName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- multiselect options for flight cets start -->
                <div class="row" *ngIf="selectedDocType === 'flightcets'">
                    <div class="input-field-task input-field example-box"
                        [ngClass]="{'col-md-4':selectedDocType === 'flightcets'}">
                        <mat-form-field appearance="fill" class="form-input">
                            <mat-label class="input-label large-label asterix">Document Type</mat-label>
                            <mat-select (selectionChange)="selectDocumentTypes($event.value)">
                                <mat-option [value]="documentType"
                                    *ngFor="let documentType of documentTypes;">{{documentType}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="input-field-task input-field example-box col-md-4" *ngIf="selectedDocType === 'flightcets'">
                        <mat-form-field appearance="fill" class="form-input">
                            <mat-label class="input-label large-label asterix">Competency</mat-label>
                            <mat-select (selectionChange)="selectCompetencies($event.value)" multiple>
                                <mat-option [value]="competencey"
                                    *ngFor="let competencey of competencies;">{{competencey}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row" *ngIf="selectedDocType === 'flightcets'">
                    <div class="input-field-task input-field example-box"
                        [ngClass]="{'col-md-4':selectedDocType === 'flightcets'}">
                        <mat-form-field appearance="fill" class="form-input">
                            <mat-label class="input-label large-label asterix">OB ID</mat-label>
                            <mat-select (selectionChange)="selectObids($event.value)" multiple>
                                <mat-option [value]="obid"
                                    *ngFor="let obid of obids;">{{obid}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="input-field-task input-field example-box col-md-4" *ngIf="selectedDocType === 'flightcets'">
                        <mat-form-field appearance="fill" class="form-input">
                            <mat-label class="input-label large-label asterix">Tasks</mat-label>
                            <mat-select (selectionChange)="selectTasks($event.value)" multiple>
                                <mat-option [value]="task"
                                    *ngFor="let task of tasks;">{{task}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- multiselect options for flight cets end -->

                 <!-- multiselect options for ABLE start -->
                <div class="row" *ngIf="selectedDocType === 'able'">
                    <div class="input-field-task input-field example-box col-md-4" *ngIf="selectedDocType === 'able'">
                        <mat-form-field appearance="fill" class="form-input">
                            <mat-label class="input-label large-label">Engine Type</mat-label>
                            <mat-select (selectionChange)="selectEngineType($event.value)" multiple>
                                <mat-option [value]="engineType"
                                    *ngFor="let engineType of engineTypes;">{{engineType}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="input-field-task input-field example-box col-md-4" *ngIf="selectedDocType === 'able'">
                        <mat-form-field appearance="fill" class="form-input">
                            <mat-label class="input-label large-label asterix">Minor Model</mat-label>
                            <mat-select (selectionChange)="selectMinorModel($event.value)" multiple>
                                <mat-option [value]="minorModel"
                                    *ngFor="let minorModel of minorModels;">{{minorModel}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- multiselect options for ABLE end -->

                <div class="input-field-task input-field example-box" *ngFor="let control of propertyDefinitions;">
                    <mat-form-field appearance="fill" class="form-input">
                        <mat-label class="input-label large-label">
                            {{ control.propertyName | formatControlName }}
                        </mat-label>
                        <input matInput 
                            [placeholder]="'Enter '+control.propertyName | formatControlName"
                            *ngIf="control.propertyName.toLowerCase() !== 'description'" 
                            [formControlName]="control.propertyName"
                        />
                        <textarea matInput 
                                  [placeholder]="'Enter '+control.propertyName | formatControlName"
                                  *ngIf="control.propertyName.toLowerCase() === 'description'"
                                  class="custom-description-input" 
                                  [formControlName]="control.propertyName" 
                                  (input)="updateWordCount()">
                        </textarea>
                        <mat-error class="error" *ngIf="fileUploadForm.get(control.propertyName)?.hasError('required')">
                            {{ control.propertyName.charAt(0).toUpperCase() + control.propertyName.slice(1) }} is required
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div id="output">
                <div mat-dialog-actions class="buttons">
                    <button class="btn-cancel mx-2" (click)="cancelUpload()">Cancel</button>
                    <button mat-raised-button [ngClass]="{'disable-button': !fileUploadForm.valid}"
                        [disabled]="!fileUploadForm.valid" class="upload-btn" (click)="uploadFileInBlocksNew(selectedDocType)">
                        UPLOAD FILE
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>