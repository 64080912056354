import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { VideoPlayerDialogComponent } from 'src/app/components/video-player-dialog/video-player-dialog.component';
import { SharedDataService } from 'src/app/services/shared-data.service';
import { VideoService } from 'src/app/services/video-service';
import { SpinnerOverlayService } from 'src/app/services/spinner-overlay-service';
import { Video } from 'src/app/models/videos.model';
import { BtlSharedLibraryService } from 'btl-shared-library';
import { ACCEPTABLE_TYPES } from 'src/app/constants/constants';
import { INgxLoadingConfig, ngxLoadingAnimationTypes } from 'ngx-loading';
import { Router } from '@angular/router';
import { HttpEventType } from '@angular/common/http';
import { saveAs as importedSaveAs } from 'file-saver';
import { MatMenuTrigger } from '@angular/material/menu';
import { formatDate, removeProgressItem, sortVideosByCreatedDate, updateProgressItem } from 'src/app/utils/video-utils';
import { ProgressItem } from 'src/app/models/access.model';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DownloadMetadata } from '../../models/download-meta-data.model';
import { MetadataComponent } from '../metadata/metadata.component';
import { forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';



@Component({
  selector: 'app-main-video-list',
  templateUrl: './main-video-list.component.html',
  styleUrls: ['./main-video-list.component.scss']
})
export class MainVideoListComponent implements OnInit {
  @Input() docType: string[] = [];
  dataSource: any;
  videos: any[] = [];
  video_details: any[] = [];
  ascendingOrder: boolean = true;
  latestCreatedVideos: boolean = true;
  columns: any[] = ['filename', 'group', 'size', 'createdDate', 'exportStatus', 'actions'];
  dataLoad = false;
  pageSize = 7;
  showPages = 2;
  pageLength = this.pageSize * (this.showPages - 1);
  filterValue = "";
  exportStatus: string = "";
  sendToCloudStatus: boolean = false;
  showUploadBanner: boolean = false;
  loaded!: string;
  total?: string;
  percentDone!: number;
  downloadProgress: any;
  downloadId: string | undefined;
  progressItems: ProgressItem[] = []
  downloading: boolean = false;
  downloadMetadata: DownloadMetadata | undefined;
  videoDownloadStates: Map<string, boolean> = new Map<string, boolean>();
  downloadMetadataMap: Map<string, DownloadMetadata> = new Map<string, DownloadMetadata>(); // Map to store download metadata
  configLoading: INgxLoadingConfig = {};
  group: string = '';
  renditions: any;
  constructor(public dialog: MatDialog,
    private router: Router,
    private sharedDataService: SharedDataService,
    private videoService: VideoService,
    private spinnerOverlayService: SpinnerOverlayService,
    private btlSharedLibraryService: BtlSharedLibraryService,
  ) { }

  @ViewChild('menuTrigger')
  menuTrigger!: MatMenuTrigger;
  @ViewChild(MatSort) sort?: MatSort;
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  ngOnInit(): void {
    this.sharedDataService.currentTabIndex$.subscribe(index => {
      this.getAllVideos();
    });

    this.sharedDataService.newVideo$.subscribe(video => {
      this.updateVideos(video);
    })

    this.sharedDataService.showUploadBanner$.subscribe(showUploadBanner => {
      this.showUploadBanner = showUploadBanner;
    })

    this.sharedDataService.filterValue$.subscribe(filter => {
      this.filterValue = filter
      if (this.filterValue != null) {
        this.searchArtifacts();
      }
    })

    if (this.docType.includes('TSVIDEO') || this.docType.includes('MTVIDEO') || this.docType.includes('FTVIDEO')) {
      this.group = 'Flight';
      this.sendToCloudStatus = true;
    } else {
      this.group = 'Maintenance';
      this.sendToCloudStatus = false;
    }

  
  }

  getGroupValue(element: any) {
    if(element.cmsObjectInfo.docType === 'TSVIDEO' || element.cmsObjectInfo.docType === 'FLIGHTCETS' ) {
      return 'Flight';
    }else{
      return 'Maintenance';
    }
  }
  updateVideos(video: any): void {
    if (video != null) {
      this.videos.unshift(video)
      this.updateDataSource(this.videos);
    }
  }

  getAllVideos(): void {
    this.spinnerOverlayService.show()
    // call list of 3 different doctypes
    console.log("get all merged videos")
    this.fetchAndMergeVideos();
    this.dataLoad = true;
  }

  fetchAndMergeVideos(): void {
    this.spinnerOverlayService.show();
    const requests = this.docType.map((docType) =>
      this.videoService.getAllVideosFromCms(docType).pipe(
        map((response: any) => {
          if (response.data.totalPages > 0) {
            return response.data.data;
          } else {
            this.sharedDataService.updateShowUploadBanner(true);
            this.btlSharedLibraryService.updateStatusMessage(true, `Error Fetching ${docType} Data`, false);
            return [];
          }
        }),
        catchError((error) => {
          console.error(`Error fetching ${docType} data:`, error);
          this.sharedDataService.updateShowUploadBanner(true);
          this.btlSharedLibraryService.updateStatusMessage(true, `Error Fetching ${docType} Data`, false);
          return of([]); 
        })
      )
    );
    forkJoin(requests).subscribe({
      next: (responses) => {
        this.videos = responses.flat();
        this.updateDataSource(this.videos);
        this.spinnerOverlayService.hide();
      },
      error: (error) => {
        console.error('Error in one of the requests:', error);
        this.spinnerOverlayService.hide();
        this.btlSharedLibraryService.updateStatusMessage(true, `Error Fetching Videos`, false);
      },
    });
  }

  updateDataSource(videos: any) {
    this.toggleDateOrder();
    this.dataSource = new MatTableDataSource<any>(this.videos);
    this.pageLength = this.videos.length;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataLoad = true
  }

  test(_t47: any) {
    console.log('Method not implemented.');
  }

  toggleDateOrder() {
    this.latestCreatedVideos = !this.latestCreatedVideos;
    this.videos = sortVideosByCreatedDate(this.videos, false)
    this.sharedDataService.updateVideosList(this.videos);
    return this.videos;
  }


  pageChangeEvent(event: PageEvent) {
    this.dataSource.paginator = this.paginator;
  }

  formatDate(date: string) {
    return formatDate(date)
  }

  openVideoDialog(video: any): void {
    this.spinnerOverlayService.show();

    this.videoService.fetchRenditions("TSVIDEO", video.contentKey)
      .subscribe(responseThumbnail => {
        console.log("responseThumbnail : ", responseThumbnail);
        this.videoService.getCmsSassStreamUrl(video.contentKey).subscribe(
          (response) => {
            // decode response
            let encodedData = response.data;
            const decodedString = atob(encodedData);
            const decodedJsonResponse = JSON.parse(decodedString);
            response.data = decodedJsonResponse;
            video.streamingUrl = response.data.data.documentURL;
            console.log("video.streamingUrl : ", video.streamingUrl);

            console.log('Renditions fetched successfully:', responseThumbnail);
            this.renditions = responseThumbnail.data;
            // Filter to get thumbnailStreamingUrl with renditionType 'thumbnail'
            const thumbnailRenditions = this.renditions.filter((rendition: any) => rendition.renditionType === 'thumbnail');
            const thumbnailStreamingUrl = thumbnailRenditions.flatMap((rendition: any) =>
              rendition.streamingInfo.map((info: any) => info.streamingUrl)
            );
            console.log("Thumbnail Rendition thumbnailStreamingUrl: ", thumbnailStreamingUrl);
            const decodedthumbnailStreamingUrl = atob(thumbnailStreamingUrl);
            video.thumbnailStreamingUrl = decodedthumbnailStreamingUrl;
            console.log("video 169 : ", video);

            this.spinnerOverlayService.hide();
            // Open dialog only after getting the streaming URL
            const dialogRef = this.dialog.open(VideoPlayerDialogComponent, {
              width: '70%',
              data: { video }
            });
          },
          (e) => {
            console.log("Error Stream URL:", e);
            this.spinnerOverlayService.hide();
            const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred while fetching stream url.";
            // Open dialog only after getting the streaming URL
            const dialogRef = this.dialog.open(VideoPlayerDialogComponent, {
              width: '70%',
              data: { video }
            });
          }
        );
      },
        error => {
          console.log("Error fetching renditions:", error);
          if (error.status === 400) {
            // Set the default thumbnail image
            video.thumbnailStreamingUrl = '../../assets/Boeing-Logo.png';
          }
          console.log("default image for thumbnail : ", video.thumbnailStreamingUrl);
          this.videoService.getCmsSassStreamUrl(video.contentKey).subscribe(
            (response) => {
              // decode response
              let encodedData = response.data;
              const decodedString = atob(encodedData);
              const decodedJsonResponse = JSON.parse(decodedString);
              response.data = decodedJsonResponse;
              video.streamingUrl = response.data.data.documentURL;
              console.log("video.streamingUrl : ", video.streamingUrl);

              this.spinnerOverlayService.hide();
              // Open dialog only after getting the streaming URL
              const dialogRef = this.dialog.open(VideoPlayerDialogComponent, {
                width: '70%',
                data: { video }
              });
            },
            (e) => {
              console.log("Error Stream URL:", e);
              this.spinnerOverlayService.hide();
              const errorMessage = e.error && e.error.message ? e.error.message : "An error occurred while fetching stream url.";
              // Open dialog only after getting the streaming URL
              const dialogRef = this.dialog.open(VideoPlayerDialogComponent, {
                width: '70%',
                data: { video }
              });
            }
          );
        }
      );
  }

  downloadDocument(video: any) {
    this.closeMenu();
    this.loaded = "";
    this.total = "";
    this.percentDone = 0;
    window.scroll(0, 0);

    // Set download state for this video to true
    this.videoDownloadStates.set(video.contentKey, true);

    // Initialize progress for this download
    let videoMetadata = {
      fileName: video.cmsObjectInfo.docName,
      contentKey: video.contentKey
    }

    this.downloadMetadataMap.set(video.cmsObjectInfo.docObjectId, {
      fileName: video.cmsObjectInfo.docName,
      progress: 0,
      progressText: 'Downloading...',
      totalSize: '',
      remainingSize: ''
    });

    this.downloading = true;
    // Function to update download progress
    const updateDownloadProgress = (progress: number, totalSize: string, remainingSize: string) => {
      const downloadMetadata = this.downloadMetadataMap.get(video.cmsObjectInfo.docObjectId);
      if (downloadMetadata) {
        downloadMetadata.progress = progress;
        downloadMetadata.totalSize = totalSize;
        downloadMetadata.remainingSize = remainingSize;
      }
    };
    this.sharedDataService.updateUploadMetaData(videoMetadata);
    video.subscription = this.videoService.downloadArtifact(video.cmsObjectInfo.docType, video.contentKey).subscribe({
      next: event => {
        if (event.type === HttpEventType.DownloadProgress) {
          if (event.total && event.total > 0) {
            this.percentDone = Math.round(100 * event.loaded / event.total);
            this.total = this.formatBytes(event.total);
            let currentDownloadingState = event.loaded <= event.total;
            const totalSize = event.total ? this.formatBytes(event.total) : 'Unknown';
            const remainingSize = event.total && event.loaded ? this.formatBytes(event.total - event.loaded) : 'Unknown';
            const progress = Math.round(100 * event.loaded / event.total);
            updateDownloadProgress(progress, totalSize, remainingSize);

          }
          this.loaded = this.formatBytes(event.loaded);
        } else if (event.type === HttpEventType.Response) {
          this.videoDownloadStates.set(video.contentKey, false);
          this.downloading = false;
          this.sharedDataService.updateShowUploadBanner(true);
          this.btlSharedLibraryService.updateStatusMessage(true, `${video.cmsObjectInfo.docName} downloaded successfully`, true);
          importedSaveAs(event.body, video.cmsObjectInfo.docName);
          //this.progressItems = removeProgressItem(this.progressItems, video.contentKey);

          // Remove download metadata for this video when download completes
          this.downloadMetadataMap.delete(video.cmsObjectInfo.docObjectId);
        }
      },
      error: error => {
        console.log('Download Artifact Error', error);
        this.videoDownloadStates.set(video.contentKey, false);
        this.downloading = false;
        const downloadMetadata = this.downloadMetadataMap.get(video.cmsObjectInfo.docObjectId);
        if (downloadMetadata) {
          downloadMetadata.progressText = 'Download failed';
        }
        //this.progressItems = removeProgressItem(this.progressItems, video.contentKey);
        this.sharedDataService.updateShowUploadBanner(true);
        this.btlSharedLibraryService.updateStatusMessage(true, error.message, false);
        // Remove download metadata for this video when download fails
        this.downloadMetadataMap.delete(video.cmsObjectInfo.docObjectId);
      }
    });
  }

  closeMenu() {
    if (this.menuTrigger && this.menuTrigger.menuOpen) {
      this.menuTrigger.closeMenu();
    }
  }


  searchArtifacts() {
    this.dataSource.filter = this.filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const searchTerm = filter;
      return data.metadata.description.toLowerCase().includes(searchTerm) ||
        data.metadata.description.toLowerCase().includes(searchTerm) ||
        data.cmsObjectInfo.docName.toLowerCase().includes(searchTerm) ||
        data.cmsObjectInfo.docObjectId.toLowerCase().includes(searchTerm) ||
        data.cmsObjectInfo.createdDate.toLowerCase().includes(searchTerm) ||
        data.contentKey.toLowerCase().includes(searchTerm);
    };
    this.pageLength = this.dataSource.filteredData.length;
    this.showPages = this.dataSource.filteredData.length <= 5 ? 1 : this.dataSource.filteredData.length > 5 && this.dataSource.filteredData.length <= 10 ? 2 : 3;
  }

  formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Byte';
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  openMetadataDialog(row: any, type: string): void {
    console.log("Open File Upload Dialog");
    console.log("window.innerHeight : ", window.innerHeight);
    let heightRatio = 0;
    let widthRatio = 0;
    if (window.innerHeight > 900 && type === 'info') {
      heightRatio = 6;
      widthRatio = 2;
    } else if (window.innerHeight < 900 && type === 'info') {
      heightRatio = 10;
      widthRatio = 2.7;
    }

    if (window.innerHeight > 900 && type === 'edit') {
      heightRatio = 7;
      widthRatio = 2;
    } else if (window.innerHeight < 900 && type === 'edit') {
      heightRatio = 12;
      widthRatio = 2.7;
    }
    const windowHeight = (window.innerHeight * heightRatio) / 100;
    const windowWidth = (window.innerWidth * widthRatio) / 100;
    let data = {
      "docType": row.cmsObjectInfo.docType,
      "documentid": row.contentKey,
      "type": type
    }
    const dialogRef = this.dialog.open(MetadataComponent,
      {
        width: `${windowWidth}%`,
        height: `${windowHeight}%`,
        autoFocus: false,
        data: data
      });
    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  handleDelete(row: any) {
    this.spinnerOverlayService.show()
    this.videoService.deleteVideo(row.cmsObjectInfo.docType, row.contentKey).subscribe({
      next: (response) => {
        console.log('response is ', response)
        this.fetchAndMergeVideos();
        this.dataLoad = true;
      },
      error: (e) => {
        this.spinnerOverlayService.hide();
        this.sharedDataService.updateShowUploadBanner(true);
        this.btlSharedLibraryService.updateStatusMessage(true, `Failure to delete ${row.cmsObjectInfo.docName} . ${e.error.message}`, false)
      }
    }
    );
  }

  stopDownload(video: any) {
    this.videoDownloadStates.set(video.contentKey, false);
    video.subscription.unsubscribe();
  }
  sendToCloud(video: any, id: any) {

    video.cmsObjectInfo.exportStatus = "In-Progress";
    this.sendToCloudStatus = true;
    // TODO integrate API's
    this.spinnerOverlayService.show()
    this.videoService.sendToCloud(video.contentKey, video.cmsObjectInfo.docType, video.cmsObjectInfo.modifiedDate, video.cmsObjectInfo.modifiedBy).subscribe({
      next: (response) => {
        if (response.data.totalPages > 0) {
          this.video_details = response.data.data;
          this.spinnerOverlayService.hide();
        } else {
          this.spinnerOverlayService.hide();
          this.sharedDataService.updateShowUploadBanner(true);
          this.btlSharedLibraryService.updateStatusMessage(true, `Error Fetching ${this.docType} Data`, false);
        }
      },
      error: (error) => {
        console.error('Error sending to cloud:', error);
        this.spinnerOverlayService.hide()
      }
    });
  }
}

